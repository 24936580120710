import React, { useContext, useEffect, useState } from 'react'
import { ContainerPosts, Footer, Link, Newsletter } from '~/components'
import * as st from '~/assets/styl/SearchPage.module.styl'
import ArrowBackIcon from '~/assets/svg/icon-yellow-left.svg'
import { useApiBlog as api } from '~/siteApi'
import { PageProps } from 'gatsby'
import { context } from '~/layouts'

const SearchPage = ({ params: { slug } }: PageProps) => {
  const [posts, setPosts] = useState([])
  const [categories, setCategories] = useState([])
  const { currentPage } = useContext(context)
  const postsPerPage = 12
  const [totalPages, setTotalPages] = useState(0)
  const [totalPosts, setTotalPosts] = useState(0)

  const searchPostsBySlug = async () => {
    const request = await api.requestData(
      'get',
      `posts?search=${slug}&per_page=${postsPerPage}&page=${currentPage}`
    )
    setTotalPages(request?.headers['x-wp-totalpages'])
    setTotalPosts(request?.headers['x-wp-total'])
    setPosts(request.data)
  }

  const getAllCategories = async () => {
    const request = await api.requestData('get', 'categories?per_page=100')
    setCategories(request.data)
  }

  const getCategoryNameById = (categoryId) => {
    const category = categories.find((cat) => cat.id === categoryId)
    return category ? category.name : 'Categoria desconhecida'
  }

  useEffect(() => {
    searchPostsBySlug()
    getAllCategories()
  }, [slug, currentPage])

  return (
    <>
      <div style={{ paddingLeft: '0', paddingRight: '0' }} className={st.core}>
        <div className={st.grid}>
          <div className={st.header}>
            <Link href="/blog">
              <img src={ArrowBackIcon} alt="Voltar" />
              Voltar
            </Link>
            <h1 className={st.title}>Resultado da sua busca</h1>
            <p className={st.resultSearch}>
              Você buscou por <span>{`'${slug}' `}</span>e foram encontrados
              <span>
                {' '}
                {`${totalPosts} resultado${posts.length == 1 ? '' : 's'}.`}
              </span>
            </p>
          </div>
          <ContainerPosts
            categoriesName={getCategoryNameById}
            searchOrFilter
            posts={posts}
            totalPages={totalPages}
          />
        </div>
        <Newsletter categorieOrSearch />
      </div>
      <Footer />
    </>
  )
}

export default SearchPage
